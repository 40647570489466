import type {
  InteractionCondition,
  InteractionElement,
  Interaction,
  InteractionsGroupByTrigger,
  InteractionTarget,
} from './../types/index';
import { defineStore } from 'pinia';
import { ID } from '@/utils/id';
import type { TriggerEvent } from '../constants';
import { emitUpdateSelectOnPageInteraction } from '@/modules/editor/modules/preview/utils/emitToIframe';

export type State = {
  isOpen: boolean;
  currentInteraction: Interaction | null;
  isSelectOnPageForTarget: boolean;
  isSelectOnPageForTrigger: boolean;
  selectType?: 'page' | 'element';
  currentTargetId?: string;
  pageInteractions: {
    id?: string;
    interactionList: Interaction[];
  };
  hashData: string;
  activeTargetChangeID: string;
  openInteractionsDropdown: Record<string, boolean>;
};

export const useInteractionStore = defineStore('interaction', {
  state: (): State => ({
    isOpen: false,
    currentInteraction: null,
    isSelectOnPageForTarget: false,
    isSelectOnPageForTrigger: false,
    selectType: undefined,
    currentTargetId: undefined,
    pageInteractions: {
      id: '',
      interactionList: [],
    },
    hashData: '',
    activeTargetChangeID: '',
    openInteractionsDropdown: {},
  }),
  getters: {
    getIsOpen(state) {
      return state.isOpen;
    },

    getHashData(state) {
      return state.hashData;
    },

    getListInteraction(state) {
      return state.pageInteractions.interactionList;
    },

    getPageInteraction(state) {
      return state.pageInteractions;
    },
    getListInteractionGroupByTrigger(state) {
      const result: InteractionsGroupByTrigger[] = [];
      const triggerTags: Pick<
        InteractionElement<TriggerEvent, InteractionCondition>,
        'uid' | 'elementTag' | 'type' | 'productId' | 'articleId' | 'sectionId'
      >[] = state.pageInteractions.interactionList.reduce(
        (
          acc: (Pick<InteractionElement<TriggerEvent, InteractionCondition>, 'uid' | 'elementTag' | 'articleId'> & {
            type?: 'PAGE' | 'ELEMENT';
            productId?: string;
            sectionId?: string;
          })[],
          obj: Interaction,
        ) => {
          if ((obj.self?.type === 'ELEMENT' && !obj.self?.uid) || !obj.id) return acc;
          const isInclude = acc.find((el: any) => {
            if (obj.self?.type === 'ELEMENT' && el.type === 'ELEMENT') {
              const isSameUid = obj.self?.uid === el.uid;
              const isSameProductId = (obj.self?.productId ?? null) === (el.productId ?? null);
              const isSameArticleId = (obj.self?.articleId ?? null) === (el.articleId ?? null);
              return isSameUid && isSameProductId && isSameArticleId;
            }
            return obj.self?.type === 'PAGE' && el.type === 'PAGE';
          });
          if (!isInclude) {
            acc.push({
              elementTag: obj?.self?.elementTag,
              uid: obj?.self?.type === 'ELEMENT' ? obj?.self?.uid : 'PAGE',
              type: obj?.self?.type,
              productId: obj?.self?.productId,
              articleId: obj?.self?.articleId,
              sectionId: obj?.self?.sectionId,
            });
          }
          return acc;
        },
        [],
      );
      triggerTags.forEach(
        (
          el: Pick<
            InteractionElement<TriggerEvent, InteractionCondition>,
            'uid' | 'elementTag' | 'type' | 'productId' | 'articleId'
          >,
        ) => {
          const interactionsByTriggerTag: Interaction[] = state.pageInteractions.interactionList.filter((item) => {
            if (item.self?.type === 'ELEMENT' && el.type === 'ELEMENT' && item.self.uid) {
              const isSameUid = item.self?.uid === el.uid;
              const isSameProductId = (item.self?.productId ?? null) === (el.productId ?? null);
              const isSameArticleId = (item.self?.articleId ?? null) === (el.articleId ?? null);
              return isSameUid && isSameProductId && isSameArticleId;
            }
            return item.self?.type === 'PAGE' && el.type === 'PAGE';
          });
          result.push({
            ...el,
            list: interactionsByTriggerTag,
          });
        },
      );
      return result;
    },

    currentInteractionData(state) {
      return state.currentInteraction;
    },

    currentInteractionTargets(state) {
      return state.currentInteraction?.targets || [];
    },

    currentInteractionTrigger(state) {
      return state.currentInteraction?.self;
    },

    getSelectType(state) {
      return state.selectType;
    },

    getIsSelectOnPageForTarget(state) {
      return state.isSelectOnPageForTarget;
    },

    getIsSelectOnPageForTrigger(state) {
      return state.isSelectOnPageForTrigger;
    },

    getCurrentTargetId(state) {
      return state.currentTargetId;
    },
    getOpenInteractionsDropdown(state) {
      return state.openInteractionsDropdown;
    },
    getBaseNewInteraction(state) {
      return {
        id: ID(),
        index: state.pageInteractions.interactionList.length,
      };
    },
  },
  actions: {
    setActiveTargetChangeID(value: string) {
      this.activeTargetChangeID = value;
    },

    clearActiveTargetChangeID() {
      this.activeTargetChangeID = '';
    },

    setCurrentInteraction(value: Interaction) {
      this.currentInteraction = value;
    },

    setCurrentInteractionById(id?: string) {
      if (id) {
        const current = this.pageInteractions.interactionList.find((el) => el.id == id) as Interaction;
        this.currentInteraction = current;
      }
    },
    updateInteractionList(index?: number, value?: Interaction) {
      if (index === undefined || !value) return;
      this.pageInteractions.interactionList[index] = value;
    },
    deleteInteraction(index?: number) {
      if (index !== undefined) {
        this.pageInteractions.interactionList.splice(index, 1, {});
        this.currentInteraction = null;
      }
    },

    clearCurrentInteraction() {
      this.currentInteraction = null;
    },

    setIsOpen(value: boolean) {
      this.isOpen = value;
    },

    duplicateInteraction(id?: string) {
      const interaction = this.pageInteractions.interactionList.find((el) => el.id === id);
      const interactionDuplicate = JSON.parse(JSON.stringify(interaction));
      if (!interactionDuplicate) return;
      this.pageInteractions.interactionList.push({
        ...interactionDuplicate,
        id: ID(),
      });
    },

    addInteraction(data?: Interaction) {
      if (!data) return;
      this.pageInteractions.interactionList.push(data);
    },

    updateInteractionTrigger(value: InteractionElement<TriggerEvent, InteractionCondition>) {
      this.currentInteraction = {
        ...(this.currentInteraction || {}),
        self: {
          ...this.currentInteraction?.self,
          ...value,
        },
      };
    },

    updateInteractionTargets(value: InteractionElement<TriggerEvent, InteractionCondition>) {
      this.currentInteraction = {
        ...this.currentInteraction,
        targets: (
          this.currentInteraction?.targets?.map((target, index) => ({
            ...target,
            ...(target.id ? { id: (index + 1).toString() } : {}),
          })) || []
        ).concat(value),
      };
    },

    removeEmptyInteractionTargets() {
      this.currentInteraction = {
        ...this.currentInteraction,
        targets: this.currentInteraction?.targets?.filter((target) => target.uid || target.type === 'PAGE'),
      };
    },

    updateInteractionTargetAttribute({ currentTargetId, data }: { currentTargetId: string; data: InteractionTarget }) {
      this.currentInteraction = {
        ...this.currentInteraction,
        targets: (this.currentInteraction?.targets || []).map((target) => {
          if (target.id === currentTargetId) {
            return {
              ...target,
              ...data,
            };
          }
          return target;
        }),
      };
    },

    setSelectType(value: 'page' | 'element') {
      this.selectType = value;
    },

    setIsSelectOnPageForTarget(value: boolean) {
      this.isSelectOnPageForTarget = value;
    },

    setIsSelectOnPageForTrigger(value: boolean) {
      this.isSelectOnPageForTrigger = value;
    },

    setCurrentTargetId(value?: string) {
      this.currentTargetId = value;
    },
    deleteInteractionTarget(id?: string) {
      if (!id) {
        return;
      }
      this.currentInteraction = {
        ...this.currentInteraction,
        targets: (this.currentInteraction?.targets || []).filter((item) => item.id !== id),
      };
    },

    setPageInteractions(value: State['pageInteractions']) {
      this.pageInteractions = value;
    },
    setHashData(value: string) {
      this.hashData = value;
    },
    setOpenInteractionsDropdown(value: State['openInteractionsDropdown']) {
      this.openInteractionsDropdown = value;
    },

    handleCloseSelectMode(extraData?: { settingType?: 'TRIGGER' | 'TARGET' }) {
      emitUpdateSelectOnPageInteraction(false, undefined, extraData);
      this.isSelectOnPageForTarget = false;
      this.isSelectOnPageForTrigger = false;
    },
  },
});

export default useInteractionStore;
