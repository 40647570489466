<script lang="ts" setup>
import TabScroll from '../../elements/TabScroll.vue';
import TabScrollItem from '../../elements/TabScrollItem.vue';
import GModal from '../../base/GModal.vue';
import { ref } from 'vue';

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'save'): void;
}>();

type Props = {
  scrollToElement: string;
  isOpen: boolean;
  disable?: boolean;
  isLoading?: boolean;
  features?: {
    trackingCode?: boolean;
  };
  isInstant?: boolean;
  pageType?: string;
  isOriginTemplate?: boolean;
};

defineProps<Props>();

const tab = ref<HTMLDivElement>();

const scrollTop = ref(0);
const tabHeight = ref(0);

const saveThemePageMeta = () => {
  emit('save');
};

const closePageSettings = () => {
  emit('close');
};
</script>

<template>
  <GModal
    :is-open="isOpen"
    :show-btn-close="true"
    :hide-actions="false"
    label-ok="Apply Settings"
    modal-class="w-[892px]"
    container-class="top-[5%] max-w-[93.3334%] h-[90%]"
    body-class="h-[calc(100%-52px)] bg-light-400"
    :btn-ok-disable="disable"
    :loading="isLoading"
    dialog-class="modal-publish-settings"
    header-class="cursor-default h-[52px] border-b border-light-400 px-0 py-0"
    @close="closePageSettings"
    @cancel="closePageSettings"
    @ok="saveThemePageMeta">
    <template #title>Publish Settings</template>
    <template #default>
      <div id="tabScrollContainer" class="h-full overflow-y-auto py-16">
        <div ref="tab">
          <TabScroll v-if="isInstant" :width="171" :tab-height="tabHeight" :scroll-top="scrollTop" :features="features">
            <TabScrollItem id="seo" :gap="16">
              <template #label>SEO</template>
              <template #content>
                <slot name="seo"></slot>
              </template>
            </TabScrollItem>
            <TabScrollItem id="social-sharing" :gap="16">
              <template #label>Social Sharing</template>
              <template #content>
                <slot name="social-sharing"></slot>
              </template>
            </TabScrollItem>
            <TabScrollItem id="favicon" :gap="16">
              <template #label>Favicon</template>
              <template #content>
                <slot name="favicon"></slot>
              </template>
            </TabScrollItem>
            <TabScrollItem id="tracking-code" :gap="16">
              <template v-if="features?.trackingCode" #label>Install Tracking Code</template>
              <template #content>
                <slot name="tracking-code"></slot>
              </template>
            </TabScrollItem>
          </TabScroll>
          <TabScroll
            v-else
            :scroll-to-element="scrollToElement"
            :width="171"
            :tab-height="tabHeight"
            :scroll-top="scrollTop"
            :features="features">
            <TabScrollItem id="seo" :gap="16">
              <template #label>SEO</template>
              <template #content>
                <slot name="seo"></slot>
              </template>
            </TabScrollItem>
            <TabScrollItem id="social-sharing" :gap="16">
              <template #label>Social Sharing</template>
              <template #content>
                <slot name="social-sharing"></slot>
              </template>
            </TabScrollItem>
            <TabScrollItem v-if="!isOriginTemplate" id="layout-setting" :gap="16">
              <template #label>Header & Footer</template>
              <template #content>
                <slot name="layout-setting"></slot>
              </template>
            </TabScrollItem>
            <TabScrollItem id="lazyload-image" :gap="16">
              <template #label>Lazyload Image</template>
              <template #content>
                <slot name="lazyload-image"></slot>
              </template>
            </TabScrollItem>
            <TabScrollItem id="tracking-code" :gap="16">
              <template v-if="features?.trackingCode" #label>Install Tracking Code</template>
              <template #content>
                <slot name="tracking-code"></slot>
              </template>
            </TabScrollItem>
          </TabScroll>
        </div>
      </div>
    </template>
  </GModal>
</template>
