import { defineStore } from 'pinia';
import type { MAPS } from '@/modules/editor/modules/sidebar/constants/sidebar';

type Mode =
  | 'global-style'
  | 'builder'
  | 'theme-change'
  | 'theme-section'
  | 'seo-checker'
  | 'search'
  | 'shopify-apps'
  | 'ab-testing'
  | 'interaction'
  | undefined;

type ActiveTab = (typeof MAPS)[keyof typeof MAPS];

type State = {
  mode: Mode;
  elementGroupHistory: ActiveTab | undefined;
  data: Record<string, boolean | undefined>;
  title: string | undefined;
  expandedMode: boolean;
  disableExpandSidebar: boolean;
  currentActiveSidebarContainChildren: boolean;
  activeSearchFilterTab: ActiveTab;
};

const ELEMENTS_GROUP: (string | undefined)[] = ['builder', 'shopify-apps', 'sections'];
export const useSidebarStore = defineStore('sidebar', {
  state: (): State => ({
    mode: 'builder',
    title: 'Start with',
    elementGroupHistory: undefined,
    data: {},
    expandedMode: false,
    disableExpandSidebar: false,
    currentActiveSidebarContainChildren: false,
    activeSearchFilterTab: 'builder',
  }),
  getters: {
    getMode(state) {
      return state.mode;
    },
    getElementGroupHistory(state) {
      return state.elementGroupHistory;
    },
    groupState: (state) => {
      return (key: string) => {
        if (state.data[key] === undefined) {
          return true;
        }
        return state.data[key];
      };
    },
    getTitle(state) {
      return state.title;
    },
    getExpandedMode(state) {
      return state.expandedMode;
    },
    getDisableExpandSidebar(state) {
      return state.disableExpandSidebar;
    },
    getCurrentActiveSidebarContainChildren(state) {
      return state.currentActiveSidebarContainChildren;
    },
    getActiveSearchFilterTab(state) {
      return state.activeSearchFilterTab;
    },
  },
  actions: {
    setMode(mode?: State['mode']) {
      this.mode = mode;
      if (!ELEMENTS_GROUP?.includes(mode)) return;
      this.setElementGroupHistory(mode as State['elementGroupHistory']);
    },
    setElementGroupHistory(mode?: State['elementGroupHistory']) {
      this.elementGroupHistory = mode;
    },
    setTitle(title: string | undefined) {
      this.title = title;
    },
    setGroupState(key: string, state?: boolean) {
      this.data[key] = state;
    },
    setExpandedMode(value: boolean) {
      this.expandedMode = value;
    },
    setDisableExpandSidebar(value: boolean) {
      this.disableExpandSidebar = value;
    },
    setCurrentActiveSidebarContainChildren(value: boolean) {
      this.currentActiveSidebarContainChildren = value;
    },
    setActiveSearchFilterTab(value: ActiveTab) {
      this.activeSearchFilterTab = value;
    },
  },
});
